import { render, staticRenderFns } from "./employment-contract-list-release-request-page.vue?vue&type=template&id=6e2a5d33&"
import script from "./employment-contract-list-release-request-page.vue?vue&type=script&lang=js&"
export * from "./employment-contract-list-release-request-page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports